//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
  -webkit-appearance: none;

  &:not([size]):not([multiple]) {
      height: $input-height;
  }
}

select.form-control-sm {
&:not([size]):not([multiple]) {
    height: $input-height-sm;
}
}

// Show Password
.password-eye {
    &:before {
        font-family: "Material Design Icons";
        content: "\F06D0";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\F06D1";
        }
    }  
}

//checkbox
.form-check {
  display: block;
  min-height: 1.35rem;
  padding-left: 1.612em;
  margin-bottom: 0
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.612em
}

.form-check-input {
  width: 1.112em;
  height: 1.112em;
  margin-top: .194em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #dee2e6;
  appearance: none;
  color-adjust: exact;
  position: relative;
  transition: background-color .15s ease-in-out,background-position .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-check-input[type=checkbox] {
  border-radius: .25em
}

.form-check-input[type=radio] {
  border-radius: 50%
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%)
}

.form-check-input:focus {
  border-color: #c8cbcf;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none
}

.form-check-input:checked {
  background-color: #027CB5;
  border-color: #027CB5
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}
.form-check-input.remove {
  background-color: #027CB5;
  border-color: #027CB5;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 10H8H15' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
}
.form-check-input.remove:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 10H8H15' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #027CB5;
  border-color: #027CB5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .5
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
  opacity: .5
}

//input group fix

.input-group {
  .form-control + .input-group-text {
    border-radius: 0 .25rem .25rem 0;
  }
}