input[type=date] {
    &::-webkit-calendar-picker-indicator{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: transparent
    }
}

.data-table-holder {
  overflow: auto;
  min-height: 500px;
}

.table-header {

  .btn-table-cta,
  #btn-transfer-device,
  #btn-remove-device {
    margin: 0 0 8px 10px;
  }
}

#data-table {
  
  tr {
    display: flex;
    align-items: flex-start;
  }

  th {
    height: 56px;
  }

  th, td {
    flex: 1;

    @media (max-width: 768px) {
      flex: auto;
    }

    &:last-of-type {
      order: 9999;
      max-width: 60px;
      padding-left: 0;
      padding-right: 0;

      @media (max-width: 768px) {
        max-width: 30px;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

#table-info {

  .modal-content {
    min-width: 540px;
  }
}

#table-settings .list-group {

  .form-check-input {
    margin: 3px 10px 0 0;
  }

  .mdi {
    opacity: 0.5;
    transition: 0.3s;
  }

  & .list-group-item:hover .mdi {
    opacity: 1;
  }
}

.modal-device-info {
  min-width: 30%;
}

table.dataTable th:first-child,
table.dataTable td:first-child {
  max-width: 25px !important;
}

table.dataTable thead .sorting:before {
  display: none !important;
}

table.dataTable thead th:first-of-type:after,
#data-table.dataTable thead th:last-of-type:after {
  display: none !important;
}

#data-table_length label,
#data-table_filter label {
  display: flex;
  align-items: center;

  select,
  input {
    margin: 0 10px;
  }
}



#data-table_length {
  margin-left: auto;
}

.table-info-content {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;

  td {
    display: block !important;
    position: relative;
    padding: 0.75rem 1.2rem;
    border-left:0;
    order: 1 !important;
    width: 100% !important;
    height: 47px;

    & + td {
      border-top-width: 0;
    }

    &:last-of-type {
      display: none !important;
    }

    .data-table-link {
      max-width: 100%;
    }

    .text-truncate {
      max-width: 200px;
    }
  }
}

.data-table-link {
  display: block;
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-labels {

  .list-group-item {
    border-right: 0;
    height: 47px;
  }
}

//placeholders
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: .5
}

.placeholder.btn::before {
  display: inline-block;
  content: ""
}

.placeholder-xs {
  min-height: .6em
}

.placeholder-sm {
  min-height: .8em
}

.placeholder-lg {
  min-height: 1.2em
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
  50% {
      opacity: .2
  }
}

@keyframes placeholder-glow {
  50% {
      opacity: .2
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
  mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
  100% {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0
  }
}

@keyframes placeholder-wave {
  100% {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0
  }
}



//buttons for mobile bersion
@media (max-width: 768px) {

  .dt-buttons {

    button:first-of-type {
      font-size: 0;
      height: 38px;
      width: 38px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99999 6H17L11.99 12.3L6.99999 6ZM4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13V19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4H5.03999C4.20999 4 3.73999 4.95 4.24999 5.61Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      border-radius: 0.15rem;
    }

    .btn-add-contact,
    .btn-create-agreement {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z' fill='white'/%3E%3C/svg%3E%0A") !important;
    }
  }

  #data-table_filter > label {
    text-indent: -9999px;
  }

  #btn-remove-contact,
  #btn-add-device-to-agreement,
  #btn-transfer-device,
  #btn-remove-device {
    font-size: 0;
    height: 38px;
    width: 38px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  #btn-transfer-device {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99 11L3 15L6.99 19V16H14V14H6.99V11ZM21 9L17.01 5V8H10V10H17.01V13L21 9Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
  }

  #btn-add-device-to-agreement {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  //table helper for mobile
  #data-table th:not(:nth-child(1)):not(:nth-child(2)):not(:last-of-type),
  #data-table td:not(:nth-child(1)):not(:nth-child(2)):not(:last-of-type) {
    display: none;
  }

  #data-table th:last-of-type a {
    display: none;
  }

  #data-table td:last-of-type a {
    float: right;
  }

  #table-info {

    .modal-dialog {
      width: 100%;
    }

    .modal-content {
      padding-left: 0;
      padding-right: 0;
      min-width: auto;
    }

    .table-labels {

      .list-group-item {
        font-size: 12px;
      }
    }
  }

  //mobile version for loader table

  .loader-table td:not(:first-child) {
    display: none;
  }
}
