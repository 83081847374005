.paginate_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 2px;
}

span .paginate_button {

  &.current {
    color: $component-active-color !important;
    background-color: $component-active-bg !important;
  }

  &:focus {
    box-shadow: $pagination-focus-box-shadow !important;
  }
}

span .paginate_button:hover {
  color: $pagination-hover-color;
  background-color: $pagination-hover-bg;
}