//
// summernote.scss
//

@font-face {
    font-family: "summernote";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/summernote.eot");
    src: url("../fonts/summernote.eot?#iefix") format("embedded-opentype"), url("../fonts/summernote.woff?") format("woff"), url("../fonts/summernote.ttf?") format("truetype")
}

.note-editor {
    &.note-frame {
        border: 1px solid $input-border-color;
        box-shadow: none;
        margin: 0;

        .note-statusbar {
            background-color: $input-bg;
            border-top: 1px solid $input-border-color;
        }

        .note-editable {
            border: none;
            background-color: $input-bg !important;
            color: $input-color !important;
        }
    }
}

.note-status-output {
    display: none;
}

.note-placeholder {
    color: $input-placeholder-color;
}

.note-toolbar {
    padding: 3px 3px 8px 8px !important;
}

.note-air-popover {
    top: 0px !important;
}

.note-editable {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: $input-bg !important;
    padding: $input-padding-y $input-padding-x !important;
    color: $input-color !important;

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.note-btn-group {
    .btn-light {
        background-color: lighten($gray-300, 5%);
        box-shadow: none;
        border-color: lighten($gray-300, 5%);
        color: $gray-800;
    }
}

.note-popover .popover-content,
.card-header.note-toolbar {
    background: $card-cap-bg;
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
    background-color: $primary;
}

.note-editor.note-airframe .note-placeholder,
.note-editor.note-frame .note-placeholder {
    padding-left: $input-padding-x;
}