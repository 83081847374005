.currency-control {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .custom-select,
  .form-control {
    width: auto;
  }
}

.dashboard-title {
  height: 38px;
  line-height: 38px;
}

.visually-hidden {
  display: none;
}

.videoview-holder {
  min-height: 350px;
  position: relative;
  z-index: 1;
  background: #363636;
  overflow: hidden;

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
  }

  #qr-aim {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 125px;
    height: 125px;
    z-index: 3;
    transform: translate(-50%, -50%);
    border: 1px dashed #f00;
    pointer-events: none;
  }
}