#custom-table_length label,
#custom-table_filter label {
  display: flex;
  align-items: center;

  select,
  input {
    margin: 0 10px;
  }
}

table.dataTable th:first-child,
table.dataTable td:first-child {
  max-width: 25px !important;
}

table.dataTable thead .sorting:before {
  display: none !important;
}

table.dataTable thead th:first-of-type:after,
#custom-table.dataTable thead th:last-of-type:after {
  display: none !important;
}

#custom-table th:last-of-type, 
#custom-table td:last-of-type {
  max-width: 60px;
}